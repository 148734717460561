<template>
    <div v-if="errorMsg.length > 0" class="error-wrapper">
        <ion-card v-for="msg in errorMsg" :key="msg" color="light" class="rounded">
            <p class="error-message-p" @click="close(msg)">{{ msg }}</p>
        </ion-card>
    </div>
</template>

<script>
    import { IonCard } from "@ionic/vue";


    export default ({
        name: 'ErrorDisplay',
        components: { IonCard },
        props: {
            errorMsg: {type: Array, required: true},
        },
        methods: {
            close(msg) {
                this.$emit('deleteErrorMsg', msg)
            }
        }
    })
</script>

<style scoped>
    .error-message-p {
        color: #ff1e21 !important;
        font-weight: bold;
        font-size: 16px;
        margin: 5px;
        padding: 5px;
    }
</style>
