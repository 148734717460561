<template>
  <ion-page>
    <ion-content class="cWhite ion-padding bg ">
      <div class="maxWidth">
        <img class="logoSign" src="../../../public/assets/icon/logoHeaderSign.svg" >
        <ErrorDisplay @deleteErrorMsg="deleteErrorMsg" :errorMsg="errorMsg"/>
        <div v-if="!isConfirmPhone">
          <ion-label>Quel est le numéro lié à ton compte ?</ion-label>
          <ion-input v-model="phone" placeholder="0780045877"></ion-input>
          <ion-button @click="confirmPhone">Confirmer</ion-button>
        </div>
        <div v-else>
          <div class='flex'>
            <ion-label class="labelTitle">Nouveau mot de passe</ion-label>
            <ion-label class="labelSubTitle" color="medium">(8 caractères, une majuscule, une minuscule, un caractère spécial et un chiffre)</ion-label>
          </div>
          <ion-input v-model="newPassword" type="password" placeholder="J<3AllooPharma"/>

          <div class='flex'>
            <ion-label class="labelTitle">Confirmation du mot de passe</ion-label>
          </div>
          <ion-input v-model="confirmNewPassword" type="password" placeholder="J<3AllooPharma"/>

          <div class='flex'>
            <ion-label class="labelTitle">Entre le code qui vient de t'être envoyé par SMS</ion-label>
          </div>
          <ion-input v-model="code" type="text" placeholder="123456"></ion-input>
          <ion-button @click="confirmCode">Confirmer le code</ion-button>
        </div>
    </div>
    </ion-content>
  </ion-page>
</template>ò

<script>
  import {askResetPassword, resetPassword, signIn} from "../../services/api";
import {
  IonPage,
  IonLabel,
  IonContent,
  IonInput,
  IonButton
} from "@ionic/vue";
import router from '@/router'
import ErrorDisplay from "../../components/ErrorDisplay.vue";
export default {
  name: "ResetPassword",
  components: {
    ErrorDisplay,
  IonPage,
  IonContent,
  IonLabel,
  IonInput,
  IonButton
  },
  data() {
    return  {
      isConfirmPhone: false,
      phone: null,
      code: '',
      newPassword: '',
      confirmNewPassword: '',
      errorMsg: [],

    }
  },
  methods: {
    confirmPhone() {
      let num = this.phone;
      if (!num.startsWith('+33')) {
        num = num.substring(1);
        num = `+33${num}`;
      }
      askResetPassword(num).then((isConfirmed) => {
        this.isConfirmPhone = isConfirmed;
        if (!isConfirmed) {
            this.errorMsg = [ 'Numéro introuvable' ];
            setTimeout(() => this.errorMsg = [], 5000);
          }
      })
    },
    basicValidation() {
      if (this.newPassword !== this.confirmNewPassword) this.errorMsg.push("Les mots de passes ne sont pas identiques");
      if (this.newPassword.length < 8) this.errorMsg.push("Le mot de passe doit être plus long que 8 caractères");
      if (this.code.length !== 6) this.errorMsg.push("Le code doit être long d'exactement 6 chiffres");

      return this.errorMsg.length === 0;
    },
    deleteErrorMsg(msg) {
      const index = this.errorMsg.indexOf(msg);
      this.errorMsg.splice(index, index + 1);
    },
    confirmCode() {
      this.errorMsg = [];
      if (!this.basicValidation()) {
        setTimeout(() => this.errorMsg = [], 5000);
        return;
      }
      resetPassword(this.code, this.newPassword).then((response) => {
        signIn(response.data.email, this.newPassword).then(() => router.push("/"));
      }).catch((e) => {
        if (e.response.status === 404) {
          this.errorMsg.push("Le code ne corresponds à aucun utilisateur");
          return;
        }
        this.errorMsg = e.response.data.errors
      })
    }
  },
}
</script>

<style scoped>
* {
  color: var(--ion-color-light)!important;
}
.maxWidth {
  max-width: 600px;
}
.logoSign{
  margin-top:28px;
  margin-bottom: 28px;;
}

ion-input {
        background-color: transparent !important;
        border-style: solid;
        border-color: var(--ion-color-light);
        width: 100%;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 10px;
        margin-bottom: 10px;

}

.labelTitle {
  margin: 8px;
  color: white;
  font-size: 18px;
}

.labelSubTitle {
  margin: 2px;
  font-size: 12px;
}

ion-content {
--background: none;
  background-color: var(--new-blue);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  padding: 10px;
}
</style>
